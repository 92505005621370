<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Assessment Schedule Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => options.id != program.id"
                    @option:selected="programSelected()"
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Batch</label>
                <div style="width: 150px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batches"
                    :clearable="false"
                    :selectable="(options) => options.id != batch.id"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Assessment Type</label>
                <div style="width: 150px">
                  <v-select
                    v-model="type"
                    label="name"
                    :options="types"
                    :clearable="false"
                    :selectable="(options) => options != type"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Level</label>
                <div style="width: 150px">
                  <v-select
                    v-model="level"
                    label="name"
                    :options="levels"
                    :selectable="(options) => options != level"
                    :clearable="false"
                    @option:selected="fetchData()"
                  >
                  </v-select>
                </div>
                  
                <div @click="refreshData" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>

            
            <div class="flex flex-row-reverse d-flex align-items-center">
              <svg @click="searchQuery = ''" v-if="searchQuery" xmlns="http://www.w3.org/2000/svg" fill="gray" class="icon-css text-secondary border p-1" style="width: 38px; height: 38px; color: dimgray;" viewBox="0 0 384 512">
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
              </svg>
              <!-- <i style="color: #4a81d4" class="fa-solid fa-xmark"></i> -->
              <input 
                name="search"
                id="search"
                type="text"  
                class="form-control ms-2" style="width: 200px;" 
                v-model="searchQuery"/>

                Search :
            </div>

            <!-- <place-holder v-if="loading"></place-holder> -->
            <div class="table-responsive mt-4" v-if="!loading">
              <DataTable2 
                v-if="tableData.length > 0 && !loadingTable"
                :data="tableData"
                :columns="columns"
                :table="title"
                :loading="loadingTable"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                @update:pagination="handlePagination"
              >
              </DataTable2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import DataTable2 from '@/components/shared/DataTable2.vue';
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { exportExcel } from "../../../utlis/excel-export-already-formated-utlis";
import { exportPdf } from "../../../utlis/pdf-export-already-formated-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    DataTable2
  },
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batches: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "InClass",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      program: "",
      batch: "",
      level: "",
      type: "",
      title: "Assessment Schedule Report Lists",
      tableData: [],
      columns: [
        { data: 'employee_id', title: 'Employee ID' },
        { data: 'employee_name', title: 'Employee Name' },
        { data: 'program_name', title: 'Program Name' },
        { data: 'program_batch_no', title: 'Batch' },
        { data: 'level', title: 'Program Level' },
        { data: 'date', title: 'Date' },
        { data: 'time', title: 'Time' },
        { data: 'assessor_employee_name', title: 'Assessor Name' },
        { data: 'type', title: 'Assessment Type' },
        { data: 'assessment_location', title: 'Assessment Location' },
        { data: 'remark', title: 'Remark' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      searchQuery: '',
      searchTimeout: null,
      ignoreNextEmit: false,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      loadingTable: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },

  watch: {
    searchQuery() {
      // Clear the previous timeout if there was one
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout
      this.searchTimeout = setTimeout(() => {
        this.fetchData();
      }, 1000); // 
    }
  },

  methods: {
    async programSelected(){
      this.getBatch();
      this.fetchData();
    },
    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/program-batches/list?program_id=${this.program.id}`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
      });
    },

    async fetchTableData({ currentPage, pageSize }) {
      this.loading = true;
      this.loadingTable = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/assessment-schedule-report`, {
          params: {
            page: currentPage,
            per_page: pageSize,
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            level: this.level.name ?? '',
            type: this.type.name ?? '',
            search: this.searchQuery,
          },
        });
        this.tableData = response.data.data;
        this.totalRecords = response.data.total;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
      } catch (error) {
        this.toast.error("Something went wrong.");
        console.error('Error fetching table data:', error);
      } finally {
        this.loading = false;
        this.loadingTable = false;
        this.$Progress.finish();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/assessment-schedule-report`, {
          params: {
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            level: this.level.name ?? '',
            type: this.type.name ?? '',
            search: this.searchQuery,
          },
        });
        if(response){
          return response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }

      return false;
    },


    fetchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      // this.searchQuery = '';
      this.fetchTableData({ currentPage: this.currentPage, pageSize: this.pageSize });
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
      console.log(this.currentPage, this.pageSize);
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.fetchTableData({ currentPage, pageSize });
      }
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.level = "";
      this.type = "";
      this.searchQuery = '';
      this.fetchData();
    },
    async exportPdfClick() {
      const apiData = await this.getWithoutPaginate();
      if(apiData && apiData.length > 0){
        this.pdfLoading = true;
        exportPdf(apiData, this.columns, "Assessment-Schedule-Report");

        this.pdfLoading = false;
      }else{
        return this.toast.error("There is no data to export!");
      }
    },
    async exportExcelClick() {
      const apiData = await this.getWithoutPaginate();
      if (apiData && apiData.length > 0) {
        this.excelLoading = true;

        exportExcel(apiData, this.columns, "OJT-Assessment-Report");
        this.excelLoading = false;
      } else {
        return this.toast.error("There is no data to export!");
      }
    },
  },
  created() {
    
    this.getAllLevels();
    this.fetchData();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
